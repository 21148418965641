export default {
  methods: {
    log(type, { method, data, error }) {
      const name = this.$route.name;
      const url = location.href;

      const options = Object.assign(
        {
          name,
          url,
          method,
        },
        type === 'error' ? { error } : { data }
      );

      this.$store.dispatch('log/create', { options });
    },
  },
};
